var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"eb1f8d408dd9200864e3cac549ed1429af146aaa"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { IS_DEV } from '@/lib/constants';
import * as Sentry from '@sentry/nextjs';

if (!IS_DEV) {
    Sentry.init({
        dsn: 'https://5dbdea3849a54ab1874c57d83f4cb9aa@o1373131.ingest.sentry.io/6771739', // TODO: from config/env
        // Don't send any transaction events
        tracesSampleRate: 0.0,
        // ...
        // Note: if you want to override the automatic release value, do not set a
        // `release` value here - use the environment variable `SENTRY_RELEASE`, so
        // that it will also get attached to your source maps
    });
}
